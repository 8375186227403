var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "12" } },
            [
              _c(
                "card",
                {
                  attrs: { title: _vm.$t("portfolio.title") },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function () {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.createPortfolio()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "", small: "" } }, [
                                _vm._v("fa fa-plus"),
                              ]),
                              _vm._v(
                                " " + _vm._s(_vm.$t("actions.create")) + " "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("grid-view", {
                    ref: "grid",
                    attrs: {
                      headers: _vm.headers,
                      endpoint: _vm.getEndpoint(),
                      model: "Portfolio",
                      loading: _vm.loading,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function (ref) {
                          var item = ref.item
                          return [_vm._v(" " + _vm._s(item.name) + " ")]
                        },
                      },
                      {
                        key: "item.devise",
                        fn: function (ref) {
                          var item = ref.item
                          return [_vm._v(" " + _vm._s(item.devise) + " ")]
                        },
                      },
                      {
                        key: "item.createur",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(item.createur.name) + " "),
                          ]
                        },
                      },
                      {
                        key: "item.createdAtFormatted",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(" " + _vm._s(item.createdAtFormatted) + " "),
                          ]
                        },
                      },
                      {
                        key: "item.portfolioVersions.name",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.getLastVersionName(item)) + " "
                            ),
                          ]
                        },
                      },
                      {
                        key: "item._actions",
                        fn: function (ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewIndex(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-eye"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.activatePortfolio(item)
                                  },
                                },
                              },
                              [
                                item.status
                                  ? _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fa fa-unlock-alt"),
                                    ])
                                  : _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("fa fa-lock"),
                                    ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editPortfolio(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("mdi-pencil"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewSetting(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fa fa-cogs"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: !item.hasCompanyManual,
                                  icon: "",
                                  small: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.exportExcel(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("fa fa-download"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  disabled: !item.hasCompanyManual,
                                  small: "",
                                  icon: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.importExcel(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("fa fa-upload"),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _vm.editDialog
            ? _c("Form", {
                attrs: { portfolioId: _vm.editedPortfolioId },
                on: { save: _vm.onDialogSave },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.importDialog,
            callback: function ($$v) {
              _vm.importDialog = $$v
            },
            expression: "importDialog",
          },
        },
        [
          _vm.importDialog
            ? _c("ImportData", {
                attrs: { portfolioId: _vm.editedPortfolioId },
                on: { save: _vm.onDialogImportSave },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }