

















































































import { Component, Vue } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import Form from "@/pages/portefeuille/Form";
import ImportData from "@/pages/portefeuille/ImportData";
import GridView from "@/components/GridView";
import { State } from "vuex-class";
import { AuthUser } from "@/smartmsi";

@Component({ components: { GridView, Form, ImportData }, mixins: [formatter] })
export default class PortfolioIndex extends Vue {
  @State user!: AuthUser;
  editDialog = false;
  importDialog = false;
  portfolio = {
    name: "",
    devise: "",
  };
  editedPortfolioId = null;
  loading = true;



  getEndpoint() {
    // Define the base endpoint URL
    let endpoint = 'portfolio?expand=createur';

    // Adjust the endpoint based on the user's role
    if (this.user?.role != "admin") {
      // For other roles, fetch portfolios based on specific criteria
      endpoint += '&filter[createur.id][like]=' + this.user?.id; // Adjust the URL parameter according to your backend API
    }

    return endpoint;
  }

  get headers() {
    return [
      { text: '', value: '_actions', },
      { text: this.$i18n.t('name'), value: 'name', },
      { text: this.$i18n.t('devise'), value: 'devise', },
      { text: this.$i18n.t('createur'), value: 'createur.name', },
      { text: this.$i18n.t('date creation'), value: 'createdAtFormatted', },
      { text: this.$i18n.t('version'), value: 'lastVersion.name', },
    ];
  }

  createPortfolio() {
    this.editedPortfolioId = null;
    this.portfolio = {
      name: "",
      devise: "",
    }
    this.editDialog = true;
  }

  editPortfolio(portfolio) {
    this.editedPortfolioId = portfolio.id;
    this.editDialog = true;
  }

  importExcel(portfolio) {
    this.editedPortfolioId = portfolio.id;
    this.importDialog = true;
  }

  async exportExcel(portfolio) {
    try {
      let id = parseInt(portfolio.id) || 0;

      // Make the API call
      const response = await this.$api.get(`portfolio/export?id=${id}`, { responseType: 'blob' });

      // Handle the response
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', portfolio.name + '.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      if (err.response) {
        this.error = err.response.data;
      } else {
        this.error = 'An unexpected error occurred';
      }
    }
  }

  onDialogImportSave(portfolio) {
    this.$refs.grid.getModels();
    this.importDialog = false;
    this.editedPortfolioId = null;
  }

  onDialogSave(portfolio) {
    this.$refs.grid.getModels();
    this.editDialog = false;
    this.editedPortfolioId = null;
  }

  getLastVersionName(item) {
    if (item.portfolioVersions.length > 0) {
      const lastVersion = item.portfolioVersions[item.portfolioVersions.length - 1];
      return lastVersion.name;
    }
    return ''; // Handle case where portfolioVersions is empty
  }

  viewSetting(item) {
    // Encode the name parameter
    const encodedName = encodeURIComponent(item.name);
    this.$router.push(`/portfolio/${item.id}/setting/${encodedName}/${item.devise}`);
  }

  viewIndex(index) {
    this.$router.push(`/portfolio/${index.id}`);
  }
  activatePortfolio(index) {
    let active = 'désactiver'; let activeVal = 1;
    if (index.status == 1) {
      activeVal = 0;
      active = 'désactiver';
    } else {
      activeVal = 1;
      active = 'activer';
    }

    this.$root.$confirm
      .open(
        "Activation portfolio",
        "Êtes-vous sûr(e) de vouloir " + active + " ce portfolio ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api.post(`portfolio/active-portfolio?id=${index.id}&status=${activeVal}`).
            then(response => {
              this.$refs.grid.getModels();
            }).catch((e) => {
              this.error = e.response.data;
            })
        }
      });
  }

}
